import Axios from 'axios'
import {Toast} from 'vant'

const base64ToFile = (dataUrl, name) => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], name + ".jpg", {type: "image/jpg"});
};

// 文件上传请求
export const UploadFile = function (ossData, imgData) {
  return new Promise((resolve, reject) => {

    const {host, accessId, dir, policy, signature} = ossData;
    const data = new FormData();
    data.append("key", dir);
    data.append("OSSAccessKeyId", accessId);
    data.append("policy", policy);
    data.append("Signature", signature);
    data.append("success_action_status", "200");
    data.append("file", base64ToFile(imgData, "img"));
    const baseUrl = host;
    const toast = Toast.loading({
      duration: 0,
      message: '上传中...',
      forbidClick: true,
    });
    Axios({
      url: baseUrl,
      method: "post",
      data: data,
      headers: {"Content-Type": "multipart/form-data"},
    })
      .then((res) => {
        if (res.status === 200) {
          Toast.success('上传成功')
          const imgOssUrl = `${baseUrl}${dir}?x-oss-process=style/compress`;
          return resolve(imgOssUrl)
        }
      })
      .catch((e) => {
        Toast.fail('上传图片失败')
        return reject(e)
      }).finally(params => {
      toast.clear()
    })
  })
}
